<template>
  <div class="orderManagement">
    <ds-header title="订单管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="订单编号：">
        <a-input v-model.trim="searchForm.orderNo" placeholder="请输入订单号" class="inputClass" />
      </a-form-item>
      <a-form-item label="下单时间：">
        <a-range-picker @change="onChange" v-model="orderTime" />
      </a-form-item>
      <a-form-item label="用户编号：">
        <a-input v-model="searchForm.userNum" placeholder="请输入用户编号" class="inputClass" />
      </a-form-item>
      <a-form-item label="手机号码：">
        <a-input v-model="searchForm.mobile" placeholder="请输入用户手机号" class="inputClass" />
      </a-form-item>
      <a-form-item label="商品类型：">
        <a-select
          v-model="searchForm.productType"
          class="inputClass"
          placeholder="全部"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in goodsSort" :key="option.commonsValue" :value="option.commonsValue">{{
            option.commonsValueName
          }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="商品编号：">
        <a-input v-model="searchForm.productNo" placeholder="请输入商品编号" style="width: 200px" />
      </a-form-item>
      <a-form-item label="商品名称：">
        <a-input v-model="searchForm.productName" placeholder="请输入商品名称" class="inputClass" />
      </a-form-item>
      <a-form-item label="项目名称：">
        <a-select
          placeholder="请选择项目"
          v-model="searchForm.projectId"
          class="inputClass"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in loadProject" :key="option.id" :value="option.id">{{
            option.projectName
          }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="订单状态：">
        <a-select
          placeholder="请选择订单状态"
          class="inputClass"
          @change="handleChange"
          v-model="searchForm.orderStatus"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="item in state" :value="item.value" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="兑换方式：">
        <a-select
          v-model="searchForm.payTypes"
          placeholder="全部"
          class="inputClass"
        >
          <a-select-option value="">全部</a-select-option>
          <a-select-option :value="0">仅积分</a-select-option>
          <a-select-option :value="1">组合支付（积分+现金）</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="串码状态：">
        <a-select
          v-model="searchForm.stringCodeStatus"
          placeholder="全部"
          class="inputClass"
        >
          <a-select-option value="">全部</a-select-option>
          <a-select-option :value="1">未使用</a-select-option>
          <a-select-option :value="2">已使用</a-select-option>
          <a-select-option :value="3">已过期</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="兑换串码：">
        <a-input v-model="searchForm.stringCode" placeholder="请输入兑换串码" class="inputClass" />
      </a-form-item>
      <span class="btnClass">
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div
      style="padding-top: 15px"
      v-if="$checkButtonPermissions($buttonPermissionsConfig.integralMallManagement.orderManagement.export)"
    >
      <a-button type="primary" @click="exportList">导出</a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="orderNo" slot-scope="text, record" class="action">
          <a-tooltip>
            <template slot="title">
              {{ record.orderNo }}
            </template>
            <span class="field fixedWidth">{{ record.orderNo }}</span>
          </a-tooltip>
        </span>

        <span slot="productName" slot-scope="text, record" class="action">
          <a-tooltip>
            <template slot="title">
              {{ record.productName }}
            </template>
            <span class="field fixedWidth">{{ record.productName }}</span>
          </a-tooltip>
        </span>

        <span slot="projectName" slot-scope="text, record" class="action">
          <a-tooltip>
            <template slot="title">
              {{ record.projectName }}
            </template>
            <span class="field fixedWidth">{{ record.projectName }}</span>
          </a-tooltip>
        </span>

        <span slot="gmtCreate" slot-scope="text, record" class="action">
          <a-tooltip>
            <template slot="title">
              {{ record.gmtCreate !== null ? moment(record.gmtCreate).format("YYYY-MM-DD HH:mm:ss") : "-" }}
            </template>
            <span class="field fixedWidth">{{
              record.gmtCreate !== null ? moment(record.gmtCreate).format("YYYY-MM-DD HH:mm:ss") : "-"
            }}</span>
          </a-tooltip>
        </span>
        <span slot="orderStatus" slot-scope="text, record">
          {{ getOrderStatus(text) }}
        </span>
        <div slot="action" slot-scope="text, record" class="action">
          <div>
            <a
                href="javascript:;"
                @click="viewDetal(record)"
            >查看详情</a
            >
          </div>
          <div>
            <a
                href="javascript:;"
                v-show="record.orderStatus === 0"
                @click="afterSalesOrCancelOrder(record, 1)"
            >关闭订单</a
            >
          </div>
          <div v-show="record.productType === 2 && record.orderStatus === 4 && record.codeStatus === 1">
            <a href="javascript:;" @click="confirmUsed(record)">确认使用</a>
          </div>
          <div v-show="showAfterSalesBut(record)">
            <a @click="afterSalesOrCancelOrder(record, 2)">售后处理</a>
          </div>
        </div>
      </a-table>
    </div>
    <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
<!--    关闭订单/售后处理-->
    <a-modal
        :title="operationType === 1 ? '关闭订单' : '售后处理'"
        :visible="visibleModel"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <div>
       <div class="box-center">
         根据此笔订单，您可以选择任一组合进行退还：
       </div>
        <div class="box-center">
          <a-radio-group v-model="currentOperationType">
            <a-radio :style="radioStyle" :value="0">{{ `积分（${currentAfterSalesOrder.integralFee}积分）`}}</a-radio>
            <a-radio v-show="currentAfterSalesOrder.payType === '1'" :style="radioStyle" :value="1">{{ `现金（¥${currentAfterSalesOrder.cashFee ? (currentAfterSalesOrder.cashFee).toFixed(2) : '0.00'}）` }}</a-radio>
            <a-radio v-show="currentAfterSalesOrder.payType === '1'" :style="radioStyle" :value="2">{{ `积分（${currentAfterSalesOrder.integralFee}积分）+现金（¥${currentAfterSalesOrder.cashFee ? (currentAfterSalesOrder.cashFee).toFixed(2) : '0.00'}）`}}</a-radio>
          </a-radio-group>
        </div>
        <div>
          <ul class="hit">
            <li>
              注：
            </li>
            <li>
              1、每笔订单仅可操作一次，请谨慎操作；
            </li>
            <li>
              2、该操作执行后该笔订单将失效，同时根据您所选组合原路径退还到用户账号；
            </li>
            <li>
              3、如该商品为羊毛券，操作后会失效掉用户账户中该订单所兑换的优惠券；
            </li>
            <li>
              4、如该商品为第三方串码，请仔细核实第三方订单后再进行操作。
            </li>
          </ul>
        </div>
      </div>
    </a-modal>

  </div>
</template>

<script>
import ConfirmBoxModule from "@/components/confirmBoxModule";
import ConfirmConfig from "@/utils/confirmConfig";
import moment from "moment";
import debounce from "lodash/debounce";
import {
  getOrderList,
  orderClose,
  orderExport,
  getProjectList,
  getProductList,
  confirmOrderUsed,
  handleAfterSales,
  handleCancelOrder
} from "@/api/orderList";
import {mapState} from "vuex";
export default {
  name: "orderManagement",
  components: {
    ConfirmBoxModule,
  },
  computed: {
    ...mapState({
      loadProject: (state) =>  {
        let newData = state.common.projectList && state.common.projectList.length && state.common.projectList.map (item => {
          return {
            ...item,
            projectName: item.project_name
          }
        })
        // newData.unshift({
        //   id: "全部",
        //   projectName: "全部",
        // })
        return newData;
      }
    })
  },
  data() {
    return {
      moment,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      state: [
        {
          label: '待核销',
          value: 0
        },
        {
          label: "已过期",
          value: 1,
        },
        {
          label: "已关闭",
          value: 2,
        },
        {
          label: "已售后",
          value: 3,
        },
        {
          label: "已完成",
          value: 4,
        }
      ],
      exportLoading: false, // 导出loading
      visibleExport: false, // 导出框弹出
      // 弹出框
      visibleModel: false,
      orderTime: [],
      // 当前售后的订单
      currentAfterSalesOrder: {},
      // 售后处理时选择的退款方式，0：仅积分 1：仅现金 2：积分+现金
      currentOperationType: 0,
      // 操作类型 1: 取消订单 2：售后处理
      operationType: 1,
      searchForm: {
        // 订单编号
        orderNo: null,
        // 开始时间
        startTime: null,
        // 结束时间
        endTime: null,
        // 用户编号
        userNum: null,
        // 手机号码
        mobile: null,
        // 商品类型
        productType: undefined,
        // 商品编号
        productNo: null,
        // 商品名称
        productName: null,
        // 项目ID
        projectId: undefined,
        // 订单类型
        orderStatus: undefined,
        // 兑换方式
        payTypes: undefined,
        // 串码状态
        stringCodeStatus: undefined,
        // 兑换串码
        stringCode: ''
      },
      goodsSort: [],
      table: {
        dataInfo: [],
        columns: [
          {
            title: "订单编号",
            dataIndex: "orderNo",
            key: "orderNo",
            scopedSlots: { customRender: "orderNo" },
            width: 150,
          },
          {
            title: "商品编号",
            dataIndex: "productNo",
            key: "productNo",
            width: 130,
          },
          {
            title: "商品名称",
            dataIndex: "productName",
            key: "productName",
            scopedSlots: { customRender: "productName" },
            width: 180,
          },
          {
            title: "商品类型",
            dataIndex: "productType",
            key: "productType",
            width: 120,
            customRender(text) {
              return text === 0 ? '实物' : text === 1 ? '羊毛券' : '第三方串码'
            }
          },
          {
            title: "结算价格",
            dataIndex: "settlePriceTotal",
            key: "settlePriceTotal",
            width: 100,
          },
          {
            title: '兑换方式',
            dataIndex: 'payType',
            width: 100,
            customRender: (text) => {
              return text === '0' ? '仅积分' : '组合支付'
            }
          },
          {
            title: '订单金额',
            dataIndex: 'cashFee',
            width: 100,
            customRender: (text) => {
              return text ? `¥${text}` : `¥0.00`
            }
          },
          {
            title: "数量",
            dataIndex: "productSum",
            width: 100,
          },
          {
            title: "实付金额",
            dataIndex: "cashFee",
            width: 100,
            customRender: (text) => {
              return text ? `¥${text}` : '¥0.00'
            }
          },
          {
            title: "积分消耗",
            dataIndex: "integralFee",
            key: "integralFee",
            width: 100,
            customRender: (text) => {
              return text ? text : '-'
            }
          },
          {
            title: "用户编号",
            dataIndex: "userId",
            key: "userId",
            width: 120,
          },
          {
            title: "手机号码",
            dataIndex: "mobile",
            key: "mobile",
            width: 150,
          },
          {
            title: "项目名称",
            dataIndex: "projectName",
            key: "projectName",
            scopedSlots: { customRender: "projectName" },
            width: 200,
          },
          {
            title: "下单时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
            scopedSlots: { customRender: "gmtCreate" },
            width: 150,
          },
          {
            title: "订单状态",
            dataIndex: "orderStatus",
            key: "orderStatus",
            scopedSlots: { customRender: "orderStatus" },
            width: 100,
          },
          {
            title: "核销时间",
            dataIndex: "writeOffTime",
            key: "writeOffTime",
            customRender(text) {
              return text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'
            },
            width: 150,
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      // 列表操作按钮权限配置
      actions: [
        this.$buttonPermissionsConfig.integralMallManagement.orderManagement.look,
        this.$buttonPermissionsConfig.integralMallManagement.orderManagement.close,
      ],
    };
  },
  created() {
    this.$checkTableActionIsShow(this.actions) && this.table.columns.push(this.$backTableAction({ width: "200px" }));
    this.loadProductList("product_type");
    this.getOrderList();
    // this.loadGetProjectList();
  },
  methods: {
    // 是否展示售后处理
    showAfterSalesBut(row) {
      if(row.productType === 1) {
        return row.orderStatus === 4 && row.couponStatus === 0
      } else {
        return row.orderStatus === 4
      }

    },
    // 订单状态
    getOrderStatus(val) {
      const statusObj = this.state.find(item => item.value === val)
      return statusObj && statusObj !== '{}' ? statusObj.label : '-'
    },
    // 获取商品类型项目
    async loadProductList(id) {
      const res = await getProductList(id);
      this.goodsSort = res.data;
    },
    // 获取归属项目
    // async loadGetProjectList() {
    //   const res = await getProjectList();
    //   this.loadProject = res;
    //   let obj = {
    //     id: "全部",
    //     projectName: "全部",
    //   };
    //   this.loadProject.unshift(obj);
    // },
    // 获取列表页接口
    async getOrderList() {
      const params = this.getParams();
      const res = await getOrderList(params);
      const { code, data: { items, total }, msg } = res
      if (code === "200") {
        this.table.dataInfo = items;
        this.table.pagination.total = total;
      } else {
        this.$message.error(msg)
      }
    },
    /**
     * 组合参数
     */
    getParams() {
      return {
        ...this.searchForm,
        page: this.table.pagination.current,
        size: this.table.pagination.pageSize
      }
    },
    // 选择下单时间段
    onChange(date, dateString) {
      this.searchForm.startTime = dateString[0];
      this.searchForm.endTime = dateString[1];
    },
    // 导出
    exportList() {
      let obj = {
        ...this.searchForm
      };
      this.$confirm({
        title: `确定要导出文件吗？`,
        async onOk() {
          try {
            const res = await orderExport(obj);
            const blob = new Blob([res.data]);
            const objectUrl = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = objectUrl;
            a.setAttribute("download", "订单管理.xlsx");
            a.click();
          } catch (e) {
            this.$message.error("导出失败，请联系管理员");
          }
        },
        onCancel() {},
        class: "test",
      });
    },
    // 选择类型
    handleChange(value) {
      console.log(`selected ${value}`);
    },
    //查询
    search() {
      this.table.pagination.current = 1;
      this.getOrderList();
    },
    //重置
    resetSearchForm() {
      this.searchForm = {
        // 订单编号
        orderNo: null,
        // 开始时间
        startTime: undefined,
        // 结束时间
        endTime: null,
        // 用户编号
        userNum: null,
        // 手机号码
        mobile: null,
        // 商品类型
        productType: undefined,
        // 商品编号
        productNo: null,
        // 商品名称
        productName: null,
        // 项目ID
        projectId: "",
        // 订单类型
        orderStatus: undefined,
      };
      this.orderTime = [];
      this.table.pagination.current = 1;
      this.getOrderList();
    },
    // 查看详情
    viewDetal(record) {
      console.log(record, "查看详情");
      this.$router.push({
        path: "/integralMallManagement/orderManagement/detail",
        query: {
          id: record.id,
        },
      });
    },
    // 关闭订单
    async orderClose(id) {
      const res = await orderClose(id);
      const { code, data, msg } = res
      if (code === "200") {
        this.$message.success("关闭订单成功");
        await this.getOrderList();
      } else {
        this.$message.error(msg);
      }
    },
    // 关闭订单
    downOrder: debounce(async function (record) {
      const confirmData = {
        title: "提示",
        content: "是否确认关闭此订单？关闭后，此笔订单将失效，积分原路径退还用户账户？",
        type: ConfirmConfig.CONFIRM_TYPE_WARNING,
      };
      await this.$refs.confirmBoxModule.show(confirmData);
      this.orderClose(record.id);
    }, 500),
    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getOrderList();
    },
    /**
     * 确认使用
     */
    confirmUsed(row) {
      console.log(row, 'row')
      const that = this
      this.$confirm({
        title: '是否确认第三方该订单已使用？',
        async onOk() {
          const res = await confirmOrderUsed(row.id)
          const { code, data, msg } = res
          if(code === '200') {
            that.$message.success('操作成功！')
            await that.getOrderList()
          }
        },
        onCancel() {},
        class: "test",
      });
    },
    /**
     *  售后处理
     */
    afterSalesOrCancelOrder(row, type) {
      this.operationType = type
      this.currentAfterSalesOrder = row
      this.currentOperationType = 0
      this.visibleModel = true
    },
    /**
     * 售后处理确认事件
     */
    async handleOk() {
      const that = this
      let value = ''
      // 仅积分
      if(this.currentOperationType === 0) {
        value = `积分（${this.currentAfterSalesOrder.integralFee}积分）`
      }
      // 仅现金
      if(this.currentOperationType === 1) {
        value = `现金（¥${this.currentAfterSalesOrder.cashFee ? (this.currentAfterSalesOrder.cashFee).toFixed(2) : '0.00'}）`
      }
      // 积分+现金
      if (this.currentOperationType === 2) {
        value = `积分（${this.currentAfterSalesOrder.integralFee}积分）+现金（¥${this.currentAfterSalesOrder.cashFee ? (this.currentAfterSalesOrder.cashFee).toFixed(2) : '0.00'}）`
      }
      const params = {
        orderId: this.currentAfterSalesOrder.id,
        type: this.currentOperationType
      }
      // 取消订单的操作
      if(this.operationType === 1) {
        await this.$confirm({
          title: '提示',
          content: h => <div>是否确认退还<span style="color: red;font-size: 18px;margin: 0 10px">{value}</span>,该操作不可逆，请谨慎操作</div>,
          async onOk() {
            const result = await handleCancelOrder(params);
            const {code, msg} = result
            if (code === '200') {
              that.$message.success('操作成功！')
              that.visibleModel = false
              await that.getOrderList()
            } else {
              that.$message.warning(msg)
            }
          },
          onCancel() {
          },
          class: "test",
        });
      }
      // 售后处理的操作
      if (this.operationType === 2) {
        await this.$confirm({
          title: '提示',
          content: h => <div>是否确认退还<span style="color: red;font-size: 18px;margin: 0 10px">{value}</span>,该操作不可逆，请谨慎操作</div>,
          async onOk() {
            const result = await handleAfterSales(params);
            const { code, msg } = result
            if(code === '200') {
              that.$message.success('操作成功！')
              that.visibleModel = false
              await that.getOrderList()
            } else {
              that.$message.warning(msg)
            }
          },
          onCancel() {
          },
          class: "test",
        });
      }
    },
    /**
     * 关闭弹窗
     */
    handleCancel() {
      this.visibleModel = false
    }
  },
};
</script>

<style lang="scss" scoped>
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
  display: flex;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}
.fixedWidth {
  max-width: 150px !important;
}
.inputClass {
  width: 200px;
}
.inputColor {
  color: #bdbdbd !important;
}
.hit {
  margin-top: 20px;
  & > li {
    color: red;
    font-weight: 600;
    list-style: none;
  }
}
.box-center {
  display: flex;
  justify-content: center;
}
</style>
